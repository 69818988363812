import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_avue_crud = _resolveComponent("avue-crud")!

  return (_openBlock(), _createBlock(_component_avue_crud, {
    option: _ctx.option,
    onRowSave: _ctx.rowREGSave,
    onRowDel: _ctx.rowREGDel,
    data: _ctx.regs
  }, null, 8, ["option", "onRowSave", "onRowDel", "data"]))
}