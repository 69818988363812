
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Api } from "@/assets/Api";
import store from "@/store";

export default defineComponent({
  name: "ConfigStopTreatmentObject",
  props: [],
  emits: ["setObjects"],
  components: {},
  setup(props, { emit }) {
    let data = reactive<any>({
      option: {},
      regs: [],
    });

    const getOption = () => {
      Api.Config.GetStopTreatmentListInfoOption().then((res: any) => {
        console.log(res);
        const allHospital: string = store.getters.allHospital;
        if (allHospital) {
          const jsonHospital = JSON.parse(allHospital);
          jsonHospital.forEach((p: any) => {
            p.children.forEach((c: any) => {
              c.children.forEach((h: any) => {
                h.value += "";
              });
            });
          });
          res.column.filter((c: any) => {
            if (c.prop == "hospital") c.dicData = jsonHospital;
          });
        }
        data.option = res;
      });
    };

    const rowREGSave = (form: any, done: any, loading: any) => {
      done();
      emit("setObjects", data.regs);
    };

    const rowREGDel = (form: any, index: any, done: any) => {
      done(form);
      emit("setObjects", data.regs);
    };

    onMounted(() => {
      getOption();
    });

    return {
      ...toRefs(data),
      rowREGSave,
      rowREGDel,
      // ...toRefs(props),
      // dialogVisible,
      // getWeekclosedays,
      // getTqdays,
      // getRules,
      // saveRules,
    };
  },
});
